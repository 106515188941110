// log
import store from "../store";

const fetchDataPhaseRequest = () => {
  return {
    type: "CHECK_DATAPHASE_REQUEST",
  };
};

const fetchDataPhaseSuccess = (payload) => {
  return {
    type: "CHECK_DATAPHASE_SUCCESS",
    payload: payload,
  };
};

const fetchDataPhaseFailed = (payload) => {
  return {
    type: "CHECK_DATAPHASE_FAILED",
    payload: payload,
  };
};

export const fetchDataPhase = () => {
  return async (dispatch) => {
    dispatch(fetchDataPhaseRequest());
    try {
      let lightPhase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(1)
        .call();
      let premiumPhase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(2)
        .call();
      let elitePhase = await store
        .getState()
        .blockchain.smartContract.methods.getPhaseIds(3)
        .call();

      dispatch(
        fetchDataPhaseSuccess({
          lightPhase,
          premiumPhase,
          elitePhase,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataPhaseFailed("Could not load data from contract."));
    }
  };
};
