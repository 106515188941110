import React, { useEffect, useState, useRef, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import { fetchDataSub } from "./redux/datasub/datasubActions";
import { fetchDataBase } from "./redux/database/databaseActions";
import { fetchDataPhase } from "./redux/dataphase/dataphaseActions";

import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import CircleTextLogo from "./CircleTextLogo";
import { Canvas } from "@react-three/fiber";
import * as Loader from "react-loader-spinner";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const BackContainer = styled.div`
  display: block;
  background-image: url(/config/images/dn2.gif);
  position: fixed;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center 31vh;
  background-size: 500px;
  width: 100%;
  height: 100%;
  z-index: -100;
`;

export const StyledButtonFixed = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 10px;
  padding: 0.2rem 0.5rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);
  :active {
    padding: 0.3rem 0.6rem;
  }
  :hover {
    color: purple;
    opacity: 0.8;
  }
`;

export const StyledButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0.6rem 4rem;
  position: relative;
  overflow: hidden;
  /* background-color: rgba(181, 255, 20, 0.9); */
  background-color: rgba(0, 0, 0, 1);
  ${({ bgImage }) =>
    bgImage &&
    `
    color: rgba(0, 0, 0, 0);
    background-image: url(${bgImage});
    background-size: cover;
    background-position:center;
  `}

  :active {
    padding: 0.3rem 2rem;
  }
  :hover {
    ${({ bgImage }) =>
      bgImage &&
      `
    color: rgba(0, 0, 0, 0);
  `}
    opacity: 0.8;
  }
`;
export const StyledConnectButton = styled.button`
  font-family: AEB;
  color: #fff;
  min-width: 170px;
  min-height: 21px;
  font-size: 2rem;
  font-weight: 800;
  border-radius: 100px;
  padding: 0 3rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 1);

  /* background-image: url("/config/images/connect.jpg"); */
  background-size: cover;
  /* animation: gradation 10.7s ease-in infinite; */
  :active {
    padding: 0 2.8rem;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
  :hover {
    opacity: 0.9;
    /* background-image: url("/config/images/connect.hover.jpg"); */
  }
`;

export const StyledButtonOparator = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #ff0000;
`;

export const StyledButtonOparatorOff = styled.button`
  color: #fff;
  font-size: 0.8rem;
  display: inline;
  border-radius: 10px;
  padding: 0.6rem 2rem;
  position: inline-block;
  overflow: hidden;
  background-color: #333;
  /* background: linear-gradient(to right, #e66465, #9198e5, #4ed, yellow); */
  background-size: 700% 700%;
`;

export const StyledInput = styled.input`
  color: #777;
  font-size: 2rem;
  border-radius: 50px;
  padding: 0.6rem 3rem;
  width: 50%;
  position: relative;
  overflow: hidden;
`;

export const StyledButtonFree = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 20%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButtonvipwl = styled.button`
  padding: 10px;
  margin-left: 20px;
  border-radius: 50px;
  border: none;
  background-color: blue;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-image: url("/config/images/main/exo1.png");
  background-size: cover;
  background-position: top;
  padding: 5rem;
  /* height: 100vh; */
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const OperationContainer = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : "column")};
  justify-content: ${({ jc }) => (jc ? jc : "flex-start")};
  align-items: ${({ ai }) => (ai ? ai : "flex-start")};
  background-color: rgba(51, 51, 51, 0);
  width: 100%;
  background-size: cover;
  background-position: top;
  padding: 5rem;
  height: 10vh;
  border-radius: 2px;
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7); */
`;

export const StyledIncDecButton = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB;
  font-weight: 700;
  padding: 10px;
  border-radius: 100%;
  margin: 0 4rem;
  /* border: solid 1px #333; */
  border: none;
  background-color: rgba(255, 255, 255, 0);
  padding: 20px;
  font-weight: bold;
  font-size: 3rem;
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3); */
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;

  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 100%;
  height: 100px;
  @media (min-width: 767px) {
    width: 500px;
    height: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  /* border: 4px dashed var(--secondary); */
  /* background-color: var(--accent); */
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledButtonReload = styled.button`
  font-family: "futura-pt-bold", BIZUDPGothicB !important;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #333;
  padding: 10px;
  font-weight: bold;
  color: white;
  width: 100%;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    opacity: 0.8;
  }
`;
export const StyledImgThanks = styled.img`
  /* box-shadow: 0px 5px 11px 2px rgba(181, 255, 20, 0.7);
  border: 4px solid var(--secondary); */
  /* background-color: var(--accent);
  border-radius: 100%; */
  width: 200px;
  @media (min-width: 900px) {
    width: 300px;
  }
  @media (min-width: 1000px) {
    width: 500px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;
export const StyledLinkMini = styled.a`
  color: var(--secondary);
  font-size: 0.5rem;
  text-decoration: none;
`;

function Home() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const datasub = useSelector((state) => state.datasub);
  const database = useSelector((state) => state.database);
  const dataphase = useSelector((state) => state.dataphase);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [mintAmountWL, setmintAmountWL] = useState(1);
  const [mintAmountAL0, setMintAmountAL0] = useState(1);
  const [mintAmountAL1, setMintAmountAL1] = useState(1);
  const [mintAmountAL2, setMintAmountAL2] = useState(1);
  const [mintAmountAL3, setMintAmountAL3] = useState(1);
  const [mintAmountAL4, setMintAmountAL4] = useState(1);
  const [mintAmountAL5, setMintAmountAL5] = useState(1);
  const [mintAmountAL6, setMintAmountAL6] = useState(1);
  const [mintAmountAL7, setMintAmountAL7] = useState(1);

  const [minted, setminted] = useState(0);
  const [mintedWL, setmintedWL] = useState(0);
  const [whitelistProof0, setwhitelistProof0] = useState("");
  const [whitelistValid0, setwhitelistValid0] = useState(false);
  const [whitelistRoot0, setwhitelistRoot0] = useState("");

  const [allowlistProof0, setAllowlistProof0] = useState("");
  const [allowlistValid0, setAllowlistValid0] = useState(false);
  const [allowlistProof1, setAllowlistProof1] = useState("");
  const [allowlistValid1, setAllowlistValid1] = useState(false);
  const [allowlistProof2, setAllowlistProof2] = useState("");
  const [allowlistValid2, setAllowlistValid2] = useState(false);
  const [allowlistProof3, setAllowlistProof3] = useState("");
  const [allowlistValid3, setAllowlistValid3] = useState(false);
  const [allowlistProof4, setAllowlistProof4] = useState("");
  const [allowlistValid4, setAllowlistValid4] = useState(false);
  const [allowlistProof5, setAllowlistProof5] = useState("");
  const [allowlistValid5, setAllowlistValid5] = useState(false);
  const [allowlistProof6, setAllowlistProof6] = useState("");
  const [allowlistValid6, setAllowlistValid6] = useState(false);
  const [allowlistProof7, setAllowlistProof7] = useState("");
  const [allowlistValid7, setAllowlistValid7] = useState(false);

  const [allowlistRoot0, setAllowlistRoot0] = useState("");
  const [allowlistRoot1, setAllowlistRoot1] = useState("");
  const [allowlistRoot2, setAllowlistRoot2] = useState("");

  const [canMint0, setcanMint0] = useState(0);

  const [canMintAl0, setcanMintAl0] = useState(99);
  const [canMintAl1, setcanMintAl1] = useState(99);
  const [canMintAl2, setcanMintAl2] = useState(99);

  const [mintedAL0, setMintedAL0] = useState(0);
  const [mintedAL1, setMintedAL1] = useState(0);
  const [mintedAL2, setMintedAL2] = useState(0);

  const [videoIndex, setVideoIndex] = useState(0);
  const videoSources = [
    "/config/images/main.mp4",
    "/config/images/main2.mp4",
    "/config/images/main3.mp4",
  ];

  const [showScrollButton, setShowScrollButton] = useState(true);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  // const fetchDatas = (account) => {
  //   dispatch(fetchData(account));
  //   dispatch(fetchDataSub(account));
  // };

  const getAllowlist = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      let address = blockchain.account.toLowerCase();
      let { MerkleTree } = require("merkletreejs");
      let keccak256 = require("keccak256");

      let allowlist = require("./data/al" + _phaseId + ".json");

      let hashedAddresses = allowlist.map((addr) => keccak256(addr));
      let merkleTree = new MerkleTree(hashedAddresses, keccak256, {
        sortPairs: true,
      });
      let hashedAddress = keccak256(address);
      let proof = merkleTree.getHexProof(hashedAddress);
      let root = merkleTree.getHexRoot();
      // console.log('VIP:address/'+address+':::mintNum/'+mintNum);
      // console.log('VIP:hasheBefore/'+address+validNun);
      // console.log('VIP:hashed/'+hashedAddress);

      blockchain.smartContract.methods
        .isAllowlisted(blockchain.account, _phaseId, proof)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setAllowlistProof0(proof);
              setAllowlistValid0(receipt);
              console.log("al0:valid:" + [receipt]);
              // if (canMintAlo > 0) {
              //   setMintable0(canMintAl0 - mintedAL0);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof0);
              break;
            case 1:
              console.log("al1:valid:" + [receipt]);
              setAllowlistProof1(proof);
              setAllowlistValid1(receipt);
              // if (canMintAl1 > 0) {
              //   setMintable1(canMintAl1 - mintedAL1);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof1);
              break;
            case 2:
              setAllowlistProof2(proof);
              setAllowlistValid2(receipt);
              // if (canMintAl2 > 0) {
              //   setMintable2(canMintAl2 - mintedAL2);
              // }
              console.log("AL" + _phaseId + ":proof/" + allowlistProof2);
              break;

              break;
            default:
              console.log("not found PhaseID");
          }

          // console.log('viplistValid/'+viplistValid);
          dispatch(fetchData(blockchain.account));
        });
      // setAllowlistProof(proof);
      // setAllowlistValid(valid);
      // console.log("WL:wallet/" + blockchain.account);
      // console.log("WL:proof/" + proof);
      switch (_phaseId) {
        case 0:
          setAllowlistRoot0(root);
          console.log("AL0" + _phaseId + ":proof/" + allowlistProof0);
          break;
        case 1:
          setAllowlistRoot1(root);
          console.log("AL1" + _phaseId + ":proof/" + allowlistProof1);
          break;
        case 2:
          setAllowlistRoot2(root);
          console.log("AL2" + _phaseId + ":proof/" + allowlistProof2);
          break;
        default:
          console.log("not found PhaseID");
      }
      console.log("AL" + _phaseId + ":root/" + root);
      // console.log(whitelistValid);
    }
  };

  // setPublicSaleEnable
  const setPublicSaleEnable = (flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPublicSaleEnable(flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にPBセール開始されました！")
            : setFeedback("正常にPBセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const setALSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setAllowlistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にAL" + _phaseId + "セール開始されました！")
            : setFeedback("正常にAL" + _phaseId + "セール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const setWLSaleEnable = (_phaseId, flagBool) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setWhitelistSaleEnable(_phaseId, flagBool)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        {
          flagBool
            ? setFeedback("正常にWLセール開始されました！")
            : setFeedback("正常にWLセール停止されました！");
        }
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const wlRegist = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`SETMERKLE WL...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootWlWithId(0, whitelistRoot0)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にWL登録しました！");
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const fcfsUp = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`fcfsUpL...`);
    setClaimingNft(true);
    let _phaseId = Number(data.fcfsPhase) + 1;
    blockchain.smartContract.methods
      .setPhaseId(0, _phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にFCFSリセットしました。");
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const alUp = (_alId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let _phaseId = 0;
    switch (_alId) {
      case 0:
        _phaseId = dataphase.lightPhase + 1;
        break;
      case 1:
        _phaseId = dataphase.premiumPhase + 1;
        break;
      case 2:
        _phaseId = dataphase.elitePhase + 1;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`alUpL...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setPhaseId(_alId, _phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback(`正常にAL${_alId}リセットしました。`);
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const PublicUp = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    setFeedback(`Publicリセット中...`);
    setClaimingNft(true);
    let _phaseId = Number(data.nowPhasePs) + 1;
    blockchain.smartContract.methods
      .setNowPhasePs(_phaseId)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にPUBLICリセットしました。");
        setClaimingNft(false);
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchData(blockchain.account));
      });
  };

  const alRegist = (_phaseId) => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 2);
    let alRoot;

    switch (_phaseId) {
      case 0:
        alRoot = allowlistRoot0;
        break;
      case 1:
        alRoot = allowlistRoot1;
        break;
      case 2:
        alRoot = allowlistRoot2;
        break;
      default:
        console.log("not found PhaseID");
    }
    setFeedback(`SETMERKLE AL${_phaseId}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .setMerkleRootAlWithId(_phaseId, alRoot)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        // console.log(receipt);
        setFeedback("正常にAL" + _phaseId + "登録しました！");
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const withdraw = () => {
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", 0);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`出金中 ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .withdraw()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: 0,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `正常に出金されました！`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const checkMinted = () => {
    // if (
    //   blockchain.account !== "" &&
    //   blockchain.account !== undefined &&
    //   blockchain.smartContract !== null
    // ) {
    //   blockchain.smartContract.methods
    //     .psMinted(blockchain.account)
    //     .call()
    //     .then((receipt) => {
    //       setminted(receipt);
    //       dispatch(fetchData(blockchain.account));
    //       dispatch(fetchDataSub(blockchain.account));
    //     });
    // }
  };

  const checkMintedWL = (_phaseId) => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      blockchain.smartContract.methods
        .wlMinted(blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setmintedWL(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
        });
    }
  };

  const checkMintedAL = (_phaseId) => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      blockchain.smartContract.methods
        .alIdMinted(_phaseId, blockchain.account)
        .call()
        .then((receipt) => {
          switch (_phaseId) {
            case 0:
              setMintedAL0(receipt);
              break;
            case 1:
              setMintedAL1(receipt);
              break;
            case 2:
              setMintedAL2(receipt);
              break;
            case 3:
              setMintedAL3(receipt);
              break;
            case 4:
              setMintedAL4(receipt);
              break;
            case 5:
              setMintedAL5(receipt);
              break;
            case 6:
              setMintedAL6(receipt);
              break;
            case 7:
              setMintedAL7(receipt);
              break;
            default:
              console.log("not found PhaseID");
          }

          dispatch(fetchData(blockchain.account));
          dispatch(fetchDataSub(blockchain.account));
        });
    }
  };

  const claimNFTsWl = async (_phaseId) => {
    let cost = CONFIG.WEI_COSTWL;
    // mintAmountWl;
    // console.log("amount::" + String(_mintAmountWl));
    let amount = mintAmountWL;
    let whitelistProof;
    let canMint;
    switch (_phaseId) {
      case 0:
        // amount = Number(mintAmountWl1);
        whitelistProof = whitelistProof0;
        canMint = canMint0;
      default:
        console.log("not found PhaseID");
    }

    // let amount = mintAmountWl;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    let method = await blockchain.smartContract.methods.whitelistMint(
      _phaseId,
      amount,
      canMint,
      whitelistProof
    );
    let gas = await method.estimateGas({
      from: blockchain.account,
      value: totalCostWei,
    });
    console.log("estimateGas: " + gas);
    let gasPrice = await blockchain.web3.eth.getGasPrice();
    gasPrice = Math.round(gasPrice * 1.2);
    console.log("gasPrice: " + gasPrice);

    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .whitelistMint(_phaseId, amount, canMint, whitelistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        gasPrice: gasPrice,
        gas: gas + 50000,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedWL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsAl = (_phaseId) => {
    let cost = CONFIG.WEI_COSTAL0;
    let amount = 0;
    let allowlistProof = "";
    switch (_phaseId) {
      case 0:
        allowlistProof = allowlistProof0;
        amount = mintAmountAL0;
        cost = CONFIG.WEI_COSTAL0;
        break;
      case 1:
        // allowlistProof = allowlistProof1;
        allowlistProof = allowlistProof0;
        amount = mintAmountAL1;
        cost = CONFIG.WEI_COSTAL1;
        break;
      case 2:
        // allowlistProof = allowlistProof2;
        allowlistProof = allowlistProof0;
        amount = mintAmountAL2;
        cost = CONFIG.WEI_COSTAL2;
        break;
      default:
        console.log("not found PhaseID");
    }
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * amount); //個数を１に固定0714(ふりっきー)
    let gasMulti = 1;
    // gasMulti = 0.7 * amount;
    // gasMulti += 1;
    let totalGasLimit = String(gasLimit * gasMulti); //個数を1に固定0714(ふりっきー)
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`${CONFIG.NFT_NAME} minting....`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .allowlistMint(0, _phaseId, amount, allowlistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // maxPriorityFeePerGas: "40000000000",
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("ERROR....もう一度お試しください");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback("minted.check on opensea.");
        setClaimingNft(false);
        checkMintedAL(_phaseId);
        // dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsPbMP = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmountPBMP);
    // let totalGasLimit = String(gasLimit * mintAmountWL);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);

    blockchain.smartContract.methods
      .publicMintMP(mintAmountPBMP, publistProof)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("何かが間違っているようです。再度お試しください。");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
      });
  };

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COSTPB;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    // let totalGasLimit = String(gasLimit * mintAmount);
    let totalGasLimit = String(gasLimit * 1);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .publicMint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          // `WOW, the ${CONFIG.NFT_NAME} is yours! go visit opensea.io to view it.`
          `minted.check on opensea.`
        );
        setClaimingNft(false);
        // checkMinted();
        // dispatch(fetchData(blockchain.account));
        dispatch(fetchDataSub(blockchain.account));
        dispatch(fetchDataBase(blockchain.account));
      });
  };

  const appearAnim = (text) => {
    let textbox = "";
    text.split("").forEach((t, i) => {
      if (t !== " ") {
        if (i < 5) {
          textbox +=
            '<span style="animation-delay:.' + i + 's;">' + t + "</span>";
        } else {
          const n = (i + 10) / 10;
          if (i < 11) {
            textbox +=
              '<span style="animation-delay:' + n + 's;">' + t + "</span>";
          } else {
            textbox +=
              '<span style="animation-delay:' + n * 2 + 's;">' + t + "</span>";
          }
        }
      } else {
        textbox += t;
      }
    });
    return textbox;
  };

  const decrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount - diffNum;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = (diffNum) => {
    let newMintAmount = mintAmount + diffNum;
    if (newMintAmount > CONFIG.MAX_MINT) {
      newMintAmount = CONFIG.MAX_MINT;
    }
    setMintAmount(newMintAmount);
  };

  const decrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const incrementMintAmountAL0 = (diffNum) => {
    let newMintAmountAL = mintAmountAL0 + diffNum;
    let maxNum = canMintAl0 - mintedAL0;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL0(newMintAmountAL);
  };

  const decrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const incrementMintAmountAL1 = (diffNum) => {
    let newMintAmountAL = mintAmountAL1 + diffNum;
    let maxNum = canMintAl1 - mintedAL1;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL1(newMintAmountAL);
  };

  const decrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 - diffNum;
    if (newMintAmountAL < 1) {
      newMintAmountAL = 1;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const incrementMintAmountAL2 = (diffNum) => {
    let newMintAmountAL = mintAmountAL2 + diffNum;
    let maxNum = canMintAl2 - mintedAL2;
    if (newMintAmountAL > maxNum) {
      newMintAmountAL = maxNum;
    }
    setMintAmountAL2(newMintAmountAL);
  };

  const GoToMintPosition = () => {
    const mintBoxElement = document.getElementById("containerBox");

    if (mintBoxElement) {
      const rect = mintBoxElement.getBoundingClientRect();
      const distanceFromTop = rect.top + window.scrollY;
      window.scrollTo({
        top: distanceFromTop,
        behavior: "smooth",
      });
    } else {
      window.scrollTo({
        top: screen.height,
        behavior: "smooth",
      });
    }
  };
  const getData = async () => {
    if (
      blockchain.account !== "" &&
      blockchain.account !== undefined &&
      blockchain.smartContract !== null
    ) {
      dispatch(fetchData(blockchain.account));
      dispatch(fetchDataSub(blockchain.account));
      dispatch(fetchDataBase(blockchain.account));
      dispatch(fetchDataPhase(blockchain.account));

      let pendings = await blockchain.web3.eth.getTransactionCount(
        CONFIG.CONTRACT_ADDRESS,
        "pending"
      );
      console.dir("data:fcfsPhase:" + data.fcfsPhase);

      console.dir("pending:", pendings);
    }
  };

  const getInit = () => {
    if (blockchain.account === undefined) {
      setFeedback(`Click "Mint" to mint your NFT.`);
      setMintAmount(1);
      setminted(0);
    }
    getData();
    getAllowlist(0);
    getAllowlist(1);
    checkMintedAL(0);
    checkMintedAL(1);
    checkMintedAL(2);
  };
  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const getReload = () => {
    location.reload();
  };
  useEffect(() => {
    getConfig();
    getInit();
    let timeoutIdMute = setTimeout(() => {
      const mintBoxElement = document.getElementById("containerBox");

      if (mintBoxElement) {
        const rect = mintBoxElement.getBoundingClientRect();
        const distanceFromTop = rect.top + window.scrollY;
        window.scrollTo({
          top: distanceFromTop,
          behavior: "smooth",
        });
      } else {
        window.scrollTo({
          top: screen.height,
          behavior: "smooth",
        });
      }
    }, 30000);

    const eachTextAnimeControl = () => {
      const elements = document.querySelectorAll(".eachTextAnime");
      elements.forEach((element) => {
        const elemPos = element.getBoundingClientRect().top - 50;
        const scroll = window.scrollY;
        const windowHeight = window.innerHeight;
        if (scroll >= elemPos - windowHeight) {
          element.classList.add("appeartext");
        } else {
          element.classList.remove("appeartext");
        }
      });
    };
    // 画面が読み込まれたときの処理
    const handleLoad = () => {
      eachTextAnimeControl();
    };

    // 画面をスクロールしたときの処理
    const handleScroll = () => {
      eachTextAnimeControl();
      const scrollPercentage =
        (window.scrollY /
          (document.documentElement.scrollHeight - window.innerHeight)) *
        100;
      if (scrollPercentage >= 30) {
        setShowScrollButton(false);
      } else {
        setShowScrollButton(true);
      }
    };

    // イベントリスナーの登録
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleLoad);
      clearTimeout(timeoutIdMute);
    };
  }, []);

  useEffect(() => {
    // ビデオの再生が終了したときに呼び出されるコールバック関数
    const handleVideoEnded = () => {
      // videoIndexが最後のインデックスに達していない場合、次のビデオを再生します
      if (videoIndex < videoSources.length - 1) {
        setVideoIndex(videoIndex + 1);
      }
    };

    // ビデオのendedイベントにリスナーを追加
    const videoElement = document.getElementById("video");
    videoElement.addEventListener("ended", handleVideoEnded);

    // コンポーネントがアンマウントされるときにリスナーをクリーンアップ
    return () => {
      videoElement.removeEventListener("ended", handleVideoEnded);
    };
  }, [videoIndex]);
  // 現在のビデオファイルのURLを取得
  const currentVideoSource = videoSources[videoIndex];
  useEffect(() => {
    getInit();
  }, [blockchain.account]);

  return (
    <s.Screen>
      {blockchain.account === "" ||
      blockchain.smartContract === null ||
      blockchain.account === undefined ? (
        <></>
      ) : (
        <BackContainer />
      )}
      <div style={{ position: "fixed", right: 0, zIndex: 100 }}>
        {showScrollButton && (
          <StyledButtonFixed
            style={{ float: "right" }}
            onClick={() => GoToMintPosition()}
          >
            Mint
          </StyledButtonFixed>
        )}
      </div>
      <header id="header">
        <h1
          className=""
          style={{ fontSize: "1.5rem", width: "100%", textAlign: "center" }}
        >
          <div
            className="eachTextAnime"
            dangerouslySetInnerHTML={{
              __html: appearAnim(" AEB Figure Collection"),
            }}
          />
        </h1>
        <div id="video-area">
          <video
            id="video"
            poster=""
            webkitplaysinline="true"
            playsInline
            autoPlay
            loop
            muted
          >
            <source src={currentVideoSource} type="video/mp4"></source>
            <p>動画を再生できる環境ではありません。</p>
          </video>
        </div>
      </header>
      <s.Container
        flex={1}
        id="containerBox"
        ai={"center"}
        style={{
          padding: 0,
          backgroundColor: "rgba(0,0,0,0.6)",
          minHeight: "100vh",
        }}
        // image={CONFIG.SHOW_BACKGROUND ? "/config/images/main/bg.png" : null}
        // image={isMobile ? "/config/images/pcbg.png" : "/config/images/pcbg.png"}
        // image={isMobile ? null : null}
      >
        <ResponsiveWrapper
          flex={1}
          style={{ padding: "5rem 0" }}
          className={"kv"}
          test
        >
          {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
            <>
              <MainContainer
                flex={2}
                jc={"center"}
                ai={"center"}
                style={{ width: "100vw", overflowX: "hidden" }}
              >
                <span className="features">
                  <s.TextTitle
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    完売！！ありがとうございます！！今後ともよろしくお願いします。
                  </s.TextTitle>
                </span>
              </MainContainer>
            </>
          ) : (
            <>
              {blockchain.account === "" ||
              blockchain.smartContract === null ||
              blockchain.account === undefined ? (
                <MainContainer
                  flex={2}
                  jc={"center"}
                  ai={"center"}
                  style={{ width: "100vw", overflowX: "hidden" }}
                >
                  <span className="features">
                    <s.Container
                      ai={"center"}
                      jc={"center"}
                      style={{ height: "100vh" }}
                    >
                      {/* <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription> */}
                      {/* <CircleTextLogo /> */}
                      <s.SpacerSmall />

                      <StyledConnectButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                          GoToMintPosition();
                        }}
                      >
                        connect
                      </StyledConnectButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  </span>
                </MainContainer>
              ) : (
                <>
                  <s.Container flex={1} jc={"center"} ai={"center"}>
                    <MainContainer
                      flex={2}
                      jc={"center"}
                      ai={"center"}
                      style={{
                        width: "100vw",
                        backgroundColor: "rgba(0, 0, 0, 0)",
                        backgroundSize: "500px",
                        padding: 100,
                        color: "white",
                      }}
                    >
                      <span className="features">
                        <s.Container ai={"center"}>
                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              color: "white",
                              fontSize: "2rem",
                            }}
                          >
                            AEB FIGURE
                          </s.TextTitle>
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          <s.TextTitle
                            style={{
                              textAlign: "center",
                              fontSize: 50,
                              fontWeight: "bold",
                              color: "var(--accent-text)",
                            }}
                          >
                            {/* {("00000" + data.totalSupply).slice(-5)} */}
                            {/* {"?????"} */}
                          </s.TextTitle>
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--primary-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.SCAN_LINK}
                            >
                              {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          <s.SpacerXSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            Gas fee is required.
                          </s.TextDescription>

                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {feedback}
                          </s.TextDescription>
                          <s.SpacerLarge />
                          <s.SpacerLarge />

                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}
                          {/* セール状況ここから */}

                          {(allowlistValid0 && database.isAlEnabled0) ||
                          (allowlistValid1 && database.isAlEnabled1) ||
                          data.isPublicSaleEnabled ? (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 18,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {allowlistValid0 && database.isAlEnabled0
                                  ? "<Light>　<Premium>　<Elite>"
                                  : ""}
                                {allowlistValid1 && database.isAlEnabled1
                                  ? "　<Credit>"
                                  : ""}
                                {data.isPublicSaleEnabled ? "<PUBLIC>　" : ""}
                                <s.SpacerLarge />

                                {"Now On Sale!"}
                              </s.TextTitle>
                              {/* <s.SpacerLarge />
                              <s.SpacerLarge /> */}
                              <s.SpacerLarge />
                            </>
                          ) : (
                            <>
                              <s.TextTitle
                                style={{
                                  textAlign: "center",
                                  fontSize: 20,
                                  fontWeight: "bold",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {"Wait For Sale..."}
                              </s.TextTitle>
                            </>
                          )}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}
                          {/* セール状況ここまで */}

                          {/* Light所有者の方ここから */}
                          {/* Light所有者の方ここから */}
                          {/* Light所有者の方ここから */}
                          {/* Light所有者の方ここから */}
                          {allowlistValid0 || allowlistValid1 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl0 <= mintedAL0 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"Light Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL0 *
                                              mintAmountAL0 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {/* <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL0(canMintAl0);
                                            getData();
                                          }}
                                        >
                                          -{canMintAl0}
                                        </StyledIncDecButton> */}
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL0}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL0(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                        {/* <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL0(canMintAl0);
                                            getData();
                                          }}
                                        >
                                          +{canMintAl0}
                                        </StyledIncDecButton> */}
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {" "}
                                        {allowlistValid0 && (
                                          <StyledButton
                                            // bgImage={
                                            //   !claimingNft &&
                                            //   "/config/images/als/AEB_00.png"
                                            // }
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              claimNFTsAl(0);
                                              getData();
                                            }}
                                          >
                                            {claimingNft
                                              ? "MINTING.."
                                              : `Light ${
                                                  Math.round(
                                                    CONFIG.DISPLAY_COSTAL0 *
                                                      mintAmountAL0 *
                                                      1000
                                                  ) / 1000
                                                }`}
                                          </StyledButton>
                                        )}
                                      </s.Container>
                                      <s.SpacerSmall />
                                      {allowlistValid1 && (
                                        <>
                                          {database.isAlEnabled1 && (
                                            <s.Container
                                              ai={"center"}
                                              jc={"center"}
                                              fd={"row"}
                                            >
                                              <StyledLink
                                                target={"_blank"}
                                                href={`https://paypiement.xyz/projects/${"e610c005-3269-470e-b60c-8a8a4ba4c349"}?quantity=${mintAmountAL0}&recipientAddress=${
                                                  blockchain.account
                                                }`}
                                              >
                                                <StyledButton
                                                  bgImage={
                                                    !claimingNft &&
                                                    "/config/images/credit.png"
                                                  }
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    // e.preventDefault();
                                                  }}
                                                >
                                                  {claimingNft
                                                    ? "MINTING.."
                                                    : `Light ${
                                                        Math.round(
                                                          CONFIG.DISPLAY_COSTAL1 *
                                                            mintAmountAL1 *
                                                            1000
                                                        ) / 1000
                                                      }`}
                                                </StyledButton>
                                              </StyledLink>
                                            </s.Container>
                                          )}
                                        </>
                                      )}
                                      {/* <s.SpacerLarge /> */}
                                      {/* <s.TextDescription
                                        style={{
                                          textAlign: "center",
                                          color: "var(--accent-text)",
                                        }}
                                      >
                                        {"("}
                                        {mintedAL0}
                                        {"/"}
                                        {canMintAl0}
                                        {")"}
                                      </s.TextDescription> */}
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {/* {"アローリストセールは開始していません"} */}
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* LIGHT所有者の方ここまで */}
                          {/* LIGHT所有者の方ここまで */}
                          {/* LIGHT所有者の方ここまで */}
                          {/* LIGHT所有者の方ここまで */}

                          {/* PREMIUM所有者の方ここから */}
                          {/* PREMIUM所有者の方ここから */}
                          {/* PREMIUM所有者の方ここから */}
                          {/* PREMIUM所有者の方ここから */}
                          {allowlistValid0 || allowlistValid1 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl1 <= mintedAL1 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"Premium Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL1 *
                                              mintAmountAL1 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL1(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL1}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL1(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {allowlistValid0 && (
                                          <StyledButton
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              claimNFTsAl(1);
                                              getData();
                                            }}
                                          >
                                            {claimingNft
                                              ? "MINTING.."
                                              : `Premium ${
                                                  Math.round(
                                                    CONFIG.DISPLAY_COSTAL1 *
                                                      mintAmountAL1 *
                                                      1000
                                                  ) / 1000
                                                }`}
                                          </StyledButton>
                                        )}
                                      </s.Container>
                                      <s.SpacerSmall />
                                      {allowlistValid1 && (
                                        <>
                                          {database.isAlEnabled1 && (
                                            <s.Container
                                              ai={"center"}
                                              jc={"center"}
                                              fd={"row"}
                                            >
                                              <StyledLink
                                                target={"_blank"}
                                                href={`https://paypiement.xyz/projects/${"f234882c-62fc-4f69-94c5-ee2085696719"}?quantity=${mintAmountAL1}&recipientAddress=${
                                                  blockchain.account
                                                }`}
                                              >
                                                <StyledButton
                                                  bgImage={
                                                    !claimingNft &&
                                                    "/config/images/credit.png"
                                                  }
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {
                                                    // e.preventDefault();
                                                  }}
                                                >
                                                  {claimingNft
                                                    ? "MINTING.."
                                                    : `Premium ${
                                                        Math.round(
                                                          CONFIG.DISPLAY_COSTAL1 *
                                                            mintAmountAL1 *
                                                            1000
                                                        ) / 1000
                                                      }`}
                                                </StyledButton>
                                              </StyledLink>
                                            </s.Container>
                                          )}
                                        </>
                                      )}

                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  ></s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* PREMIUM所有者の方ここまで */}
                          {/* PREMIUM所有者の方ここまで */}
                          {/* PREMIUM所有者の方ここまで */}
                          {/* PREMIUM所有者の方ここまで */}

                          {/* ELITE所有者の方ここから */}
                          {/* ELITE所有者の方ここから */}
                          {/* ELITE所有者の方ここから */}
                          {/* ELITE所有者の方ここから */}
                          {allowlistValid0 || allowlistValid1 ? (
                            <>
                              {database.isAlEnabled0 ? (
                                <>
                                  <s.SpacerMedium />
                                  {canMintAl2 <= mintedAL2 ? (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                          }}
                                        >
                                          {"Elite Minted."}
                                        </s.TextDescription>
                                      </s.Container>
                                    </>
                                  ) : (
                                    <>
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            fontSize: "1.4rem",
                                          }}
                                        >
                                          {Math.round(
                                            CONFIG.DISPLAY_COSTAL2 *
                                              mintAmountAL2 *
                                              1000
                                          ) / 1000}
                                          ETH
                                        </s.TextDescription>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        <StyledIncDecButton
                                          style={{ lineHeight: 0.4 }}
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            decrementMintAmountAL2(1);
                                            getData();
                                          }}
                                        >
                                          ＜
                                        </StyledIncDecButton>
                                        <s.SpacerMedium />
                                        <s.TextDescription
                                          style={{
                                            textAlign: "center",
                                            color: "var(--accent-text)",
                                            padding: 20,
                                          }}
                                        >
                                          {mintAmountAL2}
                                        </s.TextDescription>
                                        <s.SpacerMedium />
                                        <StyledIncDecButton
                                          disabled={claimingNft ? 1 : 0}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            incrementMintAmountAL2(1);
                                            getData();
                                          }}
                                        >
                                          ＞
                                        </StyledIncDecButton>
                                      </s.Container>
                                      <s.SpacerLarge />
                                      <s.Container
                                        ai={"center"}
                                        jc={"center"}
                                        fd={"row"}
                                      >
                                        {allowlistValid0 && (
                                          <StyledButton
                                            disabled={claimingNft ? 1 : 0}
                                            onClick={(e) => {
                                              e.preventDefault();
                                              claimNFTsAl(2);
                                              getData();
                                            }}
                                          >
                                            {claimingNft
                                              ? "MINTING.."
                                              : `Elite ${
                                                  Math.round(
                                                    CONFIG.DISPLAY_COSTAL2 *
                                                      mintAmountAL2 *
                                                      1000
                                                  ) / 1000
                                                }`}
                                          </StyledButton>
                                        )}
                                      </s.Container>
                                      <s.SpacerSmall />
                                      {allowlistValid1 && (
                                        <>
                                          {database.isAlEnabled1 && (
                                            <s.Container
                                              ai={"center"}
                                              jc={"center"}
                                              fd={"row"}
                                            >
                                              <StyledLink
                                                target={"_blank"}
                                                href={`https://paypiement.xyz/projects/${"ae08c587-70a7-402f-ba66-ce9786b65c26"}?quantity=${mintAmountAL2}&recipientAddress=${
                                                  blockchain.account
                                                }`}
                                              >
                                                <StyledButton
                                                  bgImage={
                                                    !claimingNft &&
                                                    "/config/images/credit.png"
                                                  }
                                                  disabled={claimingNft ? 1 : 0}
                                                  onClick={(e) => {}}
                                                >
                                                  {claimingNft
                                                    ? "MINTING.."
                                                    : `Premium ${
                                                        Math.round(
                                                          CONFIG.DISPLAY_COSTAL1 *
                                                            mintAmountAL1 *
                                                            1000
                                                        ) / 1000
                                                      }`}
                                                </StyledButton>
                                              </StyledLink>
                                            </s.Container>
                                          )}
                                        </>
                                      )}

                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                      <s.SpacerLarge />
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <s.SpacerMedium />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  ></s.TextDescription>
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                          {/* ELITE所有者の方ここまで */}
                          {/* ELITE所有者の方ここまで */}
                          {/* ELITE所有者の方ここまで */}
                          {/* ELITE所有者の方ここまで */}

                          {/* ここからパブリックセール */}
                          {/* 通常パブセ */}
                          {data.isPublicSaleEnabled ? (
                            <>
                              <s.TextDescription
                                style={{
                                  textAlign: "center",
                                  color: "var(--accent-text)",
                                }}
                              >
                                {/* {"パブリックセール("}
                                {minted}
                                {"mint)"} */}
                              </s.TextDescription>

                              {CONFIG.MAX_MINT == minted ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {"Minted."}
                                  </s.TextDescription>
                                </>
                              ) : (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        fontSize: "1.4rem",
                                      }}
                                    >
                                      {Math.round(
                                        CONFIG.DISPLAY_COST * mintAmount * 1000
                                      ) / 1000}
                                      ETH
                                    </s.TextDescription>
                                  </s.Container>
                                  <s.SpacerLarge />{" "}
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledIncDecButton
                                      style={{ lineHeight: 0.4 }}
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        decrementMintAmount(1);
                                        getData();
                                      }}
                                    >
                                      ＜
                                    </StyledIncDecButton>
                                    <s.SpacerMedium />
                                    <s.TextDescription
                                      style={{
                                        textAlign: "center",
                                        color: "var(--accent-text)",
                                        padding: 20,
                                      }}
                                    >
                                      {mintAmount}
                                    </s.TextDescription>
                                    <s.SpacerMedium />
                                    <StyledIncDecButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        incrementMintAmount(1);
                                        getData();
                                      }}
                                    >
                                      ＞
                                    </StyledIncDecButton>
                                  </s.Container>
                                  <s.SpacerLarge />
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    fd={"row"}
                                  >
                                    <StyledButton
                                      disabled={claimingNft ? 1 : 0}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        claimNFTs();
                                        getData();
                                      }}
                                    >
                                      {claimingNft
                                        ? "MINTING.."
                                        : `Public ${
                                            Math.round(
                                              CONFIG.DISPLAY_COSTAL3 *
                                                mintAmountAL3 *
                                                1000
                                            ) / 1000
                                          }`}
                                    </StyledButton>
                                  </s.Container>
                                  <s.SpacerLarge />
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    {`(${minted}/${CONFIG.MAX_MINT})`}
                                  </s.TextDescription>
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                  <s.SpacerLarge />
                                </>
                              )}
                            </>
                          ) : (
                            <>
                              {/* <s.TextDescription
                              style={{
                                textAlign: "center",
                                color: "var(--accent-text)",
                              }}
                            >
                              {"パブリックセールは開始していません"}
                            </s.TextDescription> */}
                            </>
                          )}
                          {/* 通常パブセここまで */}

                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}
                          {/* スケジュールここから */}

                          {/* {allowlistValid0 && (
                            <>
                              {!database.isAlEnabled0 && (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    Light 2023/09/07 pm09:00 -
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    Premium 2023/09/07 pm09:00 -
                                  </s.TextDescription>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    Elite 2023/09/07 pm09:00 -
                                  </s.TextDescription>
                                </>
                              )}
                            </>
                          )} */}
                          {allowlistValid1 && (
                            <>
                              {!database.isAlEnabled1 ? (
                                <>
                                  <s.TextDescription
                                    style={{
                                      textAlign: "center",
                                      color: "var(--accent-text)",
                                    }}
                                  >
                                    クレジット[決済]は開始してません。
                                  </s.TextDescription>
                                </>
                              ) : (
                                <s.TextDescription
                                  style={{
                                    textAlign: "center",
                                    color: "var(--accent-text)",
                                  }}
                                >
                                  あなたはクレジット決済可能です。
                                </s.TextDescription>
                              )}
                            </>
                          )}

                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}
                          {/* スケジュールここまで */}

                          <s.SpacerMedium />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            NFT Market Place
                          </s.TextDescription>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            <StyledLink
                              target={"_blank"}
                              href={CONFIG.MARKETPLACE_LINK}
                            >
                              {CONFIG.MARKETPLACE}
                            </StyledLink>
                          </s.TextDescription>
                          <s.SpacerLarge />

                          <s.Container ai={"center"} jc={"center"} fd={"row"}>
                            <StyledButtonReload
                              onClick={(e) => {
                                e.preventDefault();
                                getReload();
                              }}
                            >
                              {claimingNft ? "Busy" : "RELOAD"}
                            </StyledButtonReload>
                          </s.Container>
                        </s.Container>

                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {blockchain.account === "" ||
                        blockchain.smartContract === null ||
                        blockchain.account === undefined ? (
                          <></>
                        ) : (
                          <>
                            <OperationContainer
                              flex={6}
                              jc={"center"}
                              ai={"center"}
                            >
                              {"0x637d25D0769f747B2742A04d249802dA85395970" ==
                                blockchain.account ||
                              "0x27d2210a381e7D5921Fbb4dF6Fa39fd875ceCB57" ==
                                blockchain.account ? (
                                <>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "1rem",
                                      left: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    {!database.isAlEnabled0 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(0, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "L-P-E.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(0, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "L-P-E.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}
                                    {!database.isAlEnabled1 ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setALSaleEnable(1, true);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "Credit.Start"}
                                      </StyledButtonOparator>
                                    ) : (
                                      <>
                                        <StyledButtonOparatorOff
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setALSaleEnable(1, false);
                                            getData();
                                          }}
                                        >
                                          {claimingNft ? "Busy" : "Credit.Stop"}
                                        </StyledButtonOparatorOff>
                                      </>
                                    )}

                                    <StyledButtonOparator
                                      onClick={(e) => {
                                        e.preventDefault();
                                        withdraw();
                                        getData();
                                      }}
                                    >
                                      {claimingNft ? "Busy" : "WITHDRAW"}
                                    </StyledButtonOparator>

                                    {allowlistRoot0 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(0);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "L.P.E登録"}
                                      </StyledButtonOparator>
                                    )}

                                    {allowlistRoot1 != "" && (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alRegist(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft ? "Busy" : "CREDIT登録"}
                                      </StyledButtonOparator>
                                    )}
                                  </s.Container>
                                  <s.Container
                                    ai={"center"}
                                    jc={"center"}
                                    style={{
                                      position: "fixed",
                                      top: "3rem",
                                      right: "1rem",
                                      width: "10rem",
                                    }}
                                  >
                                    {dataphase.lightPhase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(0);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `L-P-Eリセット(${dataphase.lightPhase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                    {dataphase.premiumPhase != null ? (
                                      <StyledButtonOparator
                                        onClick={(e) => {
                                          e.preventDefault();
                                          alUp(1);
                                          getData();
                                        }}
                                      >
                                        {claimingNft
                                          ? "Busy"
                                          : `Creditリセット(${dataphase.premiumPhase})`}
                                      </StyledButtonOparator>
                                    ) : (
                                      <></>
                                    )}
                                  </s.Container>
                                </>
                              ) : (
                                <></>
                              )}
                            </OperationContainer>
                          </>
                        )}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                        {/* オペレーションボタン */}
                      </span>
                    </MainContainer>
                  </s.Container>
                </>
              )}
            </>
          )}
        </ResponsiveWrapper>
      </s.Container>
      {/* </>
      )} */}
    </s.Screen>
  );
}

export default Home;
