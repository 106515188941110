const initialState = {
  loading: false,
  isAlEnabled4: false,
  isAlEnabled5: false,
  isAlEnabled6: false,
  isAlEnabled7: false,
  isWlEnabled: false,
  error: false,
  errorMsg: "",
};

const dataSubReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATASUB_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_SUCCESS":
      return {
        ...state,
        loading: false,
        isAlEnabled4: action.payload.isAlEnabled4,
        isAlEnabled5: action.payload.isAlEnabled5,
        isAlEnabled6: action.payload.isAlEnabled6,
        isAlEnabled7: action.payload.isAlEnabled7,
        isWlEnabled: action.payload.isWlEnabled,
        // cost: action.payload.cost,
        error: false,
        errorMsg: "",
      };
    case "CHECK_DATASUB_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataSubReducer;
